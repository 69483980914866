/* Responsive */
/* Standard resolution for mobile devices */
@media only screen and (max-width: 767px) {
  .graph-width {
    width: calc(100vw - 160px);
  }
  .pagination-button{
    height: 30px !important;
    width: 30px !important;
    font-size: 12px !important;
  }
  .programName{
    font-size: 20px !important;
  }
  .main-logo {
    width: 120px;
  }
  .admission-card{
    width: 50%;
  }
  .college-logo{
    max-width: 80px !important;
    max-height: 80px !important;
  }
  .bgImage{
    background-image: url('./assets/programDetailPage/mobileProgramBg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 200px;
  }
  .campus-card-row{
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border: 1px solid #dee2e6; 
  }
  .intake-container{
    min-height: 200px;
    width: auto !important;
    margin-left: 16px !important ;
    margin-right: 16px !important;
  }
  a {
    font-size: 1.1rem;
  }
  .program-card-wrapper .image-column img {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  p {
    font-size: 1rem;
  }
  .program-checkbox{
    margin-left: 20px !important;

  }
  .rounded-border{
    border-radius: 0px;
  }
  .stickyAdvanceFilter {
    overflow-y: auto;
    position: relative;
    top: 0;
    padding-top: 80px; /* Add top margin when sticky */
    transition: padding-top 0.5s ease; /* Transition with 0.3s duration and ease timing function */
  }
  .main-logo {
    width: 130px;
  }
  .step-count{
    height: 58%;
  }
  .carousel-width {
    max-width: calc(100vw - 173px);
  }
  .info-width{
    width: 100%;
    display: flex;
  }
  .info-width .inner-width{
    width: 100%;
  }
  .about-header{
    padding-top: 120px !important;
  }
  .banner-carousel {
    max-height: 350px;
    overflow-y: auto;
  }
  .blog-bg {
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 0.9) 22%,
        rgba(255, 255, 255, 0.8) 28%,
        rgba(255, 255, 255, 0.5) 32%,
        rgba(255, 255, 255, 0)
      ),
      url("./assets/images/blogHeader.png") no-repeat center center;
    background-size: 100% 344px;
    background-position: 100% 0%;
  }
  .blog-height {
    height: 394px;
  }

  .curve-svg {
    bottom: -55px;
    left: 0px;
    width: calc(100%+64px) !important;
    height: 57px !important;
  }

  .hero-text {
    /* font-weight: 600; */
    font-size: 3.2rem;
  }
  .hero-img {
    max-width: 100% !important;
  }
  .static-count-data {
    font-size: 2.2rem;
  }
  .static-count-title {
    font-size: 1.5rem;
  }
  .note-input {
    width: 90%;
  }
  .sections-title {
    font-size: 2rem;
  }
  .section3-card-title {
    font-size: 1.2rem;
  }
  .section3-globe {
    width: 70%;
  }
  .login-img {
    max-height: 180px;
  }
  .tab-Detail {
    color: black !important;
    width: 100% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    white-space: nowrap !important ;
  }
  .tab-active {
    color: black !important;
    border-bottom: 3px solid #2e397e !important;
    width: 100% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    white-space: nowrap !important ;
  }
}

/* Standard resolution for tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .graph-width {
    width: calc(100vw - 580px);
  }
  .bgImage{
    background-image: url('./assets/programDetailPage/bg-detail.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .main-logo {
    width: 120px;
  }
  .admission-card{
    width: 100%;
  }
  a {
    font-size: 1.1rem;
  }
  .intake-container{
    min-height: 200px;
    width: 100% !important;
  
  }
  .stickyAdvanceFilter {
    position: sticky;
    top: 0;
    padding-top: 80px; /* Add top margin when sticky */
    transition: padding-top 0.5s ease; /* Transition with 0.3s duration and ease timing function */
  }
  .rounded-border{
    border-radius: 1rem;
  }
  p {
    font-size: 1rem;
  }
  .main-logo {
    width: 130px;
  }
  .blog-bg {
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 0.9) 22%,
        rgba(255, 255, 255, 0.8) 28%,
        rgba(255, 255, 255, 0.5) 32%,
        rgba(255, 255, 255, 0)
      ),
      url("./assets/images/blogHeader.png") no-repeat center center;
    background-size: 80% 550px;
    background-position: 100% 0%;
  }
  .curve-svg {
    bottom: -48px;
    left: 0px;
    width: calc(100%+64px) !important;
    height: 57px !important;
  }
  .blog-height {
    height: 575px;
  }

  .hero-text {
    /* font-weight: 600; */
    font-size: 3.2rem;
  }
  .hero-img {
    max-width: 100% !important;
  }
  .static-count-data {
    font-size: 2.2rem;
  }
  .carousel-width {
    max-width: calc(100vw - 607px);
  }
  .static-count-title {
    font-size: 1.5rem;
  }
  .sections-title {
    font-size: 2rem;
  }
  .section3-card-title {
    font-size: 1.2rem;
  }
  .section3-globe {
    width: 50%;
  }
  .login-img {
    max-height: 180px;
  }

  .tab-Detail {
    color: black !important;
    width: 50% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    white-space: nowrap !important ;
  }
  .note-input {
    width: 80%;
  }
  .tab-active {
    color: black !important;
    border-bottom: 3px solid #2e397e !important;
    width: 60% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    white-space: nowrap !important ;
  }
}

/* Standard resolution for desktops */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .graph-width {
    width: calc(100vw - 700px);
  }
  a {
    font-size: 1.2rem;
  }
  .program-card-wrapper .image-column img {
    margin-top: 40px;
  }
  .rounded-border{
    border-radius: 1rem;
  }
  .bgImage{
    background-image: url('./assets/programDetailPage/bg-detail.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  p {
    font-size: 1.1rem;
  }
  .admission-card{
    width: 100%;
  }
  .stickyAdvanceFilter {
    position: sticky;
    top: 0;
    padding-top: 80px; /* Add top margin when sticky */
    transition: padding-top 0.5s ease; /* Transition with 0.3s duration and ease timing function */
  }
  .intake-container{
    min-height: 200px;
    width: 100% !important;
  
  }
  .blog-bg {
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 0.9) 22%,
        rgba(255, 255, 255, 0.8) 28%,
        rgba(255, 255, 255, 0.5) 32%,
        rgba(255, 255, 255, 0)
      ),
      url("./assets/images/blogHeader.png") no-repeat center center;
    background-size: 80% 550px;
    background-position: 100% 0%;
  }
  .main-logo {
    width: 130px;
  }

  .hero-text {
    /* font-weight: 700;s */
    font-size: 3.8rem;
  }
  .hero-img {
    max-width: 100% !important;
  }
  .static-count-data {
    font-size: 2.6rem;
  }
  .static-count-title {
    font-size: 1.7rem;
  }
  .sections-title {
    font-size: 2.4rem;
  }
  .section3-card-title {
    font-size: 1.4rem;
  }
  .section3-globe {
    width: 56%;
  }
  .voucher-description {
    font-size: 2rem;
  }
  .voucher-bg {
    max-width: 280px;
  }
  .voucher-title {
    font-size: 1.4rem;
  }
  .blog-bg {
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 0.9) 22%,
        rgba(255, 255, 255, 0.8) 28%,
        rgba(255, 255, 255, 0.5) 32%,
        rgba(255, 255, 255, 0)
      ),
      url("./assets/images/blogHeader.png") no-repeat center center;
    background-size: 80% 550px;
    background-position: 100% 0%;
  }
  .curve-svg {
    bottom: -46px;
    left: 0px;
    width: calc(100%+64px) !important;
    height: 57px !important;
  }
  .blog-height {
    height: 575px;
  }
  .voucher-text {
    font-size: 1.1rem;
  }
  .voucher-shop-link {
    font-size: 1.3rem;
  }
  .note-input {
    width: 45%;
  }
  .login-img {
    max-height: 180px;
  }
  .carousel-width {
    max-width: calc(100vw - 643px);
  }
  .banner-carousel {
    max-height: 330px;
    overflow-y: auto;
  }
  .tab-Detail {
    color: black !important;
    width: 50% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    white-space: nowrap !important ;
  }
  .tab-active {
    color: black !important;
    border-bottom: 3px solid #2e397e !important;
    width: 60% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    white-space: nowrap !important ;
  }
}
/* Standard resolution for large screens */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .graph-width {
    width: calc(100vw - 820px);
  }
  a {
    font-size: 1.2rem;
  }
  .admission-card{
    width: 100%;
  }
  .intake-container{
    min-height: 200px;
    width: 100% !important;
  
  }
  .bgImage{
    background-image: url('./assets/programDetailPage/bg-detail.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  p {
    font-size: 1.1rem;
  }
  .main-logo {
    width: 130px;
  }
  .blog-height {
    height: 575px;
  }
  .program-card-wrapper .image-column img {
    margin-top: 40px;
  }
  .stickyAdvanceFilter {
    position: sticky;
    top: 0;
    padding-top: 80px; /* Add top margin when sticky */
    transition: padding-top 0.5s ease; /* Transition with 0.3s duration and ease timing function */
  }
  .hero-text {
    /* font-weight: 700; */
    font-size: 4.5rem;
  }
  .rounded-border{
    border-radius: 1rem;
  }
  .blog-bg {
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 0.9) 22%,
        rgba(255, 255, 255, 0.8) 28%,
        rgba(255, 255, 255, 0.5) 32%,
        rgba(255, 255, 255, 0)
      ),
      url("./assets/images/blogHeader.png") no-repeat center center;
    background-size: 80% 550px;
    background-position: 100% 0%;
  }
  .curve-svg {
    bottom: -43px;
    left: 0px;
    width: calc(100%+64px) !important;
    height: 57px !important;
  }
  .hero-img {
    max-width: 85% !important;
  }
  .static-count-data {
    font-size: 3.3rem;
  }
  .static-count-title {
    font-size: 1.8rem;
  }
  .sections-title {
    font-size: 2.8rem;
  }
  .section3-card-title {
    font-size: 1.6rem;
  }
  .section3-globe {
    width: 64%;
  }
  .voucher-description {
    font-size: 2.7rem;
  }
  .voucher-bg {
    max-width: 320px;
  }
  .voucher-title {
    font-size: 1.6rem;
  }
  .carousel-width {
    max-width: calc(100vw - 810px);
  }
  .voucher-text {
    font-size: 1.3rem;
  }
  .voucher-shop-link {
    font-size: 1.4rem;
  }
  .login-img {
    max-height: 180px;
  }
  .banner-carousel {
    max-height: 330px;
    overflow-y: auto;
  }
  .tab-Detail {
    color: black !important;
    width: 50% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    white-space: nowrap !important ;
  }
  .tab-active {
    color: black !important;
    border-bottom: 3px solid #2e397e !important;
    width: 60% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    white-space: nowrap !important ;
  }
}

/* Standard resolution for extra-large screens */
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .graph-width {
    width: calc(100vw - 900px);
  }
  .stickyAdvanceFilter {
    position: sticky;
    top: 0;
    padding-top: 80px; /* Add top margin when sticky */
    transition: padding-top 0.5s ease; /* Transition with 0.3s duration and ease timing function */
  }
  .bgImage{
    background-image: url('./assets/programDetailPage/bg-detail.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .intake-container{
    min-height: 200px;
    width: 100% !important;
  
  }
  .admission-card{
    width: 100%;
  }
  a {
    font-size: 1.3rem;
  }
  p {
    font-size: 1.3rem;
  }
  .program-card-wrapper .image-column img {
    margin-top: 40px;
  }
  .rounded-border{
    border-radius: 1rem;
  }
  .main-logo {
    width: 170px;
  }
  .blog-bg {
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 0.9) 22%,
        rgba(255, 255, 255, 0.8) 28%,
        rgba(255, 255, 255, 0.5) 32%,
        rgba(255, 255, 255, 0)
      ),
      url("./assets/images/blogHeader.png") no-repeat center center;
    background-size: 80% 550px;
    background-position: 100% 0%;
  }
  .blog-height {
    height: 575px;
  }
  .curve-svg {
    bottom: -41px;
    left: 0px;
    width: calc(100%+64px) !important;
    height: 57px !important;
  }

  .hero-text {
    font-size: 5rem;
    margin-top: 50px;
  }
  .hero-img {
    max-width: 95% !important;
  }
  .static-count-data {
    font-size: 3rem;
  }
  .static-count-title {
    font-size: 2rem;
  }
  .sections-title {
    font-size: 4rem;
  }
  .section3-card-title {
    font-size: 2rem;
  }
  .section3-globe {
    width: 70%;
  }
  .voucher-description {
    font-size: 3.8rem;
  }
  .voucher-title {
    font-size: 2.4rem;
  }
  .voucher-text {
    font-size: 2rem;
    margin-right: 4rem;
  }
  .voucher-shop-link {
    font-size: 2.2rem;
  }
  .login-img {
    max-height: 240px;
  }
  .optionsWidth {
    min-width: calc(100vw - 1596px);
  }
  .carousel-width {
    max-width: calc(100vw - 810px);
  }
  .banner-carousel {
    max-height: 330px;
    overflow-y: auto;
  }
  .tab-Detail {
    color: black !important;
    width: 50% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    white-space: nowrap !important ;
  }
  .tab-active {
    color: black !important;
    border-bottom: 3px solid #2e397e !important;
    width: 60% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    white-space: nowrap !important ;
  }
}

@media only screen and (min-width: 1600px) {
  .graph-width {
    width: calc(100vw - 1000px);
  }
  .program-card-wrapper .image-column img {
    margin-top: 40px;
  }
  .bgImage{
    background-image: url('./assets/programDetailPage/bg-detail.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .stickyAdvanceFilter {
    position: sticky;
    top: 0;
    padding-top: 80px; /* Add top margin when sticky */
    transition: padding-top 0.5s ease; /* Transition with 0.3s duration and ease timing function */
  }
  a {
    font-size: 1.3rem;
  }
  .admission-card{
    width: 100%;
  }
  p {
    font-size: 1.3rem;
  }
  .main-logo {
    width: 170px;
  }
  .rounded-border{
    border-radius: 1rem;
  }
  .hero-text {
    font-size: 5rem;
    margin-top: 50px;
  }
  .hero-img {
    max-width: 95% !important;
  }
  .static-count-data {
    font-size: 3rem;
  }
  .static-count-title {
    font-size: 2rem;
  }
  .sections-title {
    font-size: 5rem;
  }
  .section3-card-title {
    font-size: 2rem;
  }
  .section3-globe {
    width: 80%;
  }
  .voucher-description {
    font-size: 3.8rem;
  }
  .voucher-title {
    font-size: 2.4rem;
  }
  .voucher-text {
    font-size: 2rem;
    margin-right: 4rem;
  }
  .voucher-shop-link {
    font-size: 2.2rem;
  }
  .login-img {
    max-height: 240px;
  }
  .optionsWidth {
    min-width: calc(100vw - 1596px);
  }
  .tab-Detail {
    color: black !important;
    width: 50% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    white-space: nowrap !important ;
  }
  .tab-active {
    color: black !important;
    border-bottom: 3px solid #2e397e !important;
    width: 60% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 10px !important;
    white-space: nowrap !important ;
  }
}

/* Landing Page */

.formControl:focus {
  border: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.socialIcon {
  background-color: rgba(255, 255, 255, 0.05);
  width: 32px;
  height: 32px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.socialIcon i {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

/* SideBar */
#sidebar {
  min-width: 160px;
  max-width: 160px;
  color: #fff;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
#sidebar.active {
  min-width: 60px;
  max-width: 100px;
}
/* .blog-bg{
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 20%,rgba(255, 255, 255, 0.9) 22%, rgba(255, 255, 255, 0.8) 28%, rgba(255, 255, 255, 0.5) 32%, rgba(255, 255, 255, 0)), url('./assets/images/blogHeader.png') no-repeat center center;
  background-size: 80% 550px ;
  background-position: 100% 0%;
  
}
.blog-height{
  height: 575px;
}
.curve-svg{
  bottom: -41px;
  left: 0px;
  width : calc(100%+64px) !important;
  height: 57px !important;
} */

@media (max-width: 991.98px) {
  #sidebar {
    min-width: 60px;
    max-width: 60px;
    text-align: center;
    margin-left: -80px !important;
  }
  #sidebar.active {
    margin-left: 0 !important;
  }
}
#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

/* Global */
.dashed-border-bottom-horizontal {
  border-bottom: 1px dashed var(--dark1); /* You can adjust the color and thickness of the dashed line here */
}
.grecaptcha-badge {
  visibility: hidden !important;
}

.outline-border {
  outline: 1px solid #afafaf;
  outline-offset: -1px;
  transition: all 0.3s linear;
}
.outline-border:hover {
  outline: 1px solid transparent;
  background: linear-gradient(90deg, #333 50%, transparent 0) repeat-x,
    linear-gradient(90deg, #333 50%, transparent 0) repeat-x,
    linear-gradient(0deg, #333 50%, transparent 0) repeat-y,
    linear-gradient(0deg, #333 50%, transparent 0) repeat-y;
  background-size: 4px 1px, 4px 1px, 1px 4px, 1px 4px;
  background-position: 0 0, 0 100%, 0 0, 100% 0;
  animation: linearGradientMove 0.3s infinite linear;
}
@keyframes linearGradientMove {
  100% {
    background-position: 4px 0, -4px 100%, 0 -4px, 100% 4px;
  }
}

.form-floating > .form-control:not(:placeholder-shown) ~ label::after {
  background-color: transparent !important;
}
.form-floating > .form-select ~ label::after {
  background-color: transparent !important;
}
.form-floating > .form-select ~ label {
  transform: scale(0.7) translateY(-0.9rem) translateX(0.15rem) !important;
}
.form-floating > .form-control:not(:placeholder-shown) ~ label {
  transform: scale(0.7) translateY(-0.9rem) translateX(0.15rem) !important;
}
.form-floating > .form-control:focus ~ label {
  transform: scale(0.7) translateY(-0.9rem) translateX(0.15rem) !important;
}
.form-floating > select {
  font-size: 1.4rem !important;
  padding: 1.3rem 1rem 0.5rem 1.7rem !important;
}
.form-floating > input {
  font-size: 1.4rem !important;
  padding: 2rem 2.5rem 1.1rem 1.7rem !important;
}
.form-floating > label {
  color: var(--light-gray);
  font-size: 1.3rem;
  padding-top: 0.9rem !important ;
}

/* Scroll Bar */
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  margin: 30px;
  border-radius: 10px;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--normal-ornage);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e24b21;
}

.main-scroll {
  height: calc(100vh - 40px);
  overflow-y: auto;
}

.notes {
  height: calc(100vh - 220px);
  overflow-y: auto;
}

.statIcons {
  width: 36px;
  height: 36px;
}
.PhoneInputInput {
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
}
.red-input {
  color: red;
}

/* checkbox */
.form-switch .form-check-input:focus {
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
.form-switch .form-check-input:checked {
  background-color: #2bb148;
  border-color: #2bb148;
  border: none;
}

.blue-checkbox .form-check-input[type="checkbox"]:checked {
  background-color: var(--dark1) !important;
  border-color: var(--dark1) !important;
}
.blue-checkbox .form-check-input[type="checkbox"] {
  width: 1.5rem !important;
  height: 1.5rem !important;
  border-radius: 0.5em !important;
}
.blue-checkbox .form-check-input[type="checkbox"]:focus {
  box-shadow: 0 0 0 0.25rem rgba(50, 82, 189, 0.25);
}

.orange-checkbox .form-check-input[type="checkbox"]:checked {
  background-color: var(--normal-ornage) !important;
  border-color: var(--normal-ornage) !important;
}
.orange-checkbox .form-check-input[type="checkbox"] {
  width: 1.5rem !important;
  height: 1.5rem !important;
  border-radius: 0.5em !important;
  border: none;
}
.orange-checkbox .form-check-input[type="checkbox"]:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 112, 55, 0.25);
}
/* Options */
select option:hover {
  background-color: #ccc !important;
  color: #000 !important;
}

/* Pagination */
.pagination .active {
  background-color: var(--dark1);
  color: #fff;
}

/* Fade-IN animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in;
}

.grayscale-image {
  filter: grayscale(100%);
}
.arrow-btn {
  width: 52px;
  height: 52px;
}
.btn-dark-blue {
  background-color: var(--dark1) !important;
  color: white !important;
}

.btn-dark-white {
  background-color: white !important;
  color: var(--dark1) !important;
}
.btn-dark-white:hover {
  background-color: var(--dark1) !important;
  color: white !important;
}

.btn-dark-red {
  background-color: var(--normal-red) !important;
  color: white !important;
  border: 1px solid transparent !important;
}
.btn-dark-red:hover {
  background-color: white !important;
  color: var(--normal-red) !important;
  border: 1px solid var(--normal-red) !important;
}

.btn-dark-outline-red {
  background-color: white !important;
  color: var(--normal-red) !important;
}
.btn-dark-outline-red:hover {
  background-color: var(--normal-red) !important;
  color: white !important;
}
.btn-light-red-outline {
  border: 1px solid var(--normal-red) !important;
  color: var(--normal-red) !important;
  background: transparent !important;
  background: linear-gradient(to left, transparent 50%, var(--normal-red) 50%)
    right !important;
  background-size: 210% !important;
  transition: 0.4s !important;
}
.btn-light-red-outline:hover {
  background-position: left !important;
  color: #fff !important;
  cursor: pointer !important;
}

.btn-light-green {
  border: 1px solid var(--normal-green) !important;
  color: var(--normal-green) !important;
  background: var(--normal-green) !important;
  color: #fff !important;
}

.btn-light-green-outline {
  border: 1px solid var(--normal-green) !important;
  color: var(--normal-green) !important;
  background: transparent !important;
  background: linear-gradient(to left, transparent 50%, var(--normal-green) 50%)
    right !important;
  background-size: 210% !important;
  transition: 0.4s !important;
}
.btn-light-green-outline:hover {
  background-position: left !important;
  color: #fff !important;
  cursor: pointer !important;
}
/* CMS */

@keyframes glowing {
  0% {
    opacity: 1;
    box-shadow: 0 0 5px #ff0000;
  }
  50% {
    opacity: 0.5;
    box-shadow: 0 0 20px #ff0000;
  }
  100% {
    opacity: 1;
    box-shadow: 0 0 5px #ff0000;
  }
}

.glowing-badge {
  animation: glowing 1.5s infinite;
}

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

/* Add Student */

.AddStudentNavLink .nav-link {
  border-bottom: 2px solid transparent;
  transition: 0.5s;
}

.AddStudentNavLink .nav-link:hover {
  border-bottom: 2px solid var(--dark1);
}

.AddStudentNavLink .active {
  background-color: var(--dark1) !important;
}

/* Applications */
/* Application Status Colors */
.btn-draft {
  background-color: rgba(143, 143, 143, 0.1) !important;
  color: rgb(0, 0, 0) !important;
}

.btn-offerLetterApplied {
  background-color: rgba(30, 144, 255, 0.1) !important;
  color: rgb(10, 75, 139) !important;
}

.btn-underReview {
  background-color: rgba(46, 184, 44, 0.1) !important;
  color: rgb(25, 167, 22) !important;
}

.btn-requireChanges {
  background-color: rgba(255, 192, 0, 0.1) !important;
  color: rgb(165, 126, 8) !important;
}

.btn-offerReceived {
  background-color: rgba(0, 206, 209, 0.1) !important;
  color: rgb(18, 178, 180) !important;
}

.btn-offerRefused {
  background-color: rgba(123, 104, 238, 0.1) !important;
  color: rgb(90, 69, 206) !important;
}

.btn-offerExpired {
  background-color: rgba(255, 69, 0, 0.1) !important;
  color: rgba(255, 69, 0, 1) !important;
}

.btn-deferralUnderProcess {
  background-color: rgba(100, 149, 237, 0.1) !important;
  color: rgb(58, 107, 197) !important;
}

.btn-tuitionFeePaid {
  background-color: rgba(0, 128, 0, 0.1) !important;
  color: rgb(13, 129, 13) !important;
}

.btn-tuitionFeePending {
  background-color: rgba(255, 165, 0, 0.1) !important;
  color: rgba(255, 165, 0, 1) !important;
}

.btn-visaApproved {
  background-color: rgba(95, 255, 47, 0.274) !important;
  color: rgb(43, 117, 0) !important;
}

.btn-visaRejected {
  background-color: rgba(255, 0, 0, 0.234) !important;
  color: rgb(151, 2, 2) !important;
}

.btn-refund {
  background-color: rgba(255, 182, 193, 0.267) !important;
  color: rgb(214, 81, 101) !important;
}

.btn-applicationClosed-StudentNotQualified {
  background-color: rgba(148, 0, 211, 0.1) !important;
  color: rgb(117, 14, 161) !important;
}

.btn-applicationClosed-FeesNotPaid {
  background-color: rgba(255, 20, 147, 0.1) !important;
  color: rgb(194, 39, 122) !important;
}

.btn-applicationClosed-ProgClosedAppInProcess {
  background-color: rgba(123, 104, 238, 0.1) !important;
  color: rgb(90, 69, 206) !important;
}

/* Application Status Colors END */

.btn-light-blue-outline {
  border: 1px solid var(--dark1) !important;
  color: var(--dark1) !important;
  background: transparent !important;
  background: linear-gradient(to left, transparent 50%, #2e397e 50%) right !important;
  background-size: 210% !important;
  transition: 0.4s !important;
}
.btn-light-blue-outline:hover {
  background-position: left !important;
  color: #fff !important;
  cursor: pointer !important;
}
/* custom.css */
.custom-modal .modal-content {
  border-radius: 2.5rem !important;
}
.table {
  --bs-table-bg: transparent !important;
}
.custom-table thead tr th {
  --bs-table-color: white !important;
}

.custom-table th,
.custom-table td {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.custom-table tbody tr:nth-of-type(even) {
  background-color: var(--stripped1) !important;
}
.custom-table tbody tr:nth-of-type(odd) {
  background-color: var(--stripped2) !important;
}

/* pay table */
.custom-table1 thead tr th {
  --bs-table-color: white !important;
}

.custom-table1 th,
.custom-table1 td {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.cards {
  transition: transform 0.3s ease-in-out;
}

.cards.active {
  transform: scale(1.1);
}

/* Border animation */

.borderContainer {
  position: relative;
}

.borderContainer.onLoad::before {
  z-index: 2;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-right: 2px solid var(--normal-red);
  border-left: 2px solid var(--normal-red);
  animation: fillBorder 2s forwards;
}

@keyframes fillBorder {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

/* Animation */
.floating-filter-button {
  position: fixed;
  bottom: 20px;
  right: 10px;
  background-color: #f44336;
  color: #fff;
  cursor: pointer;
  z-index: 99999999999999; /* Ensure the button stays on top of other elements */
}


.floating-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #f44336;
  color: #fff;
  cursor: pointer;
  z-index: 99999999999999; /* Ensure the button stays on top of other elements */
}
.grecaptcha-badge {
  z-index: 99999999999999; /* Ensure the button stays on top of other elements */
}
.floating-button:hover {
  background-color: #e53935;
}

/* Date picker */
.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
  height: 100% !important;
}

.customDatePickerWidth,
.react-datepicker,
.react-datepicker__current-month {
  font-size: 1.4rem !important;
}
.customDatePickerWidth > .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 3.2rem !important;
  height: 3rem !important;
}
.react-datepicker__day-names {
  margin-bottom: -16px !important;
  margin-top: 2px !important;
}
.react-datepicker-popper[data-placement^="bottom"] {
  z-index: 3;
}
.react-datepicker-popper[data-placement^="top"] {
  z-index: 3;
}



.countrySelectedDropDownItem {
  display: block;
  width: 100%;
  padding: 2px 6px;
  clear: both;
  font-weight: 400;
  color: var(--dark1);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  background-color: #fff;
  color: #000;
}

.countrySelectedDropDownItem.active {
  background-color: var(--dark1) !important;
  color: var(--dark1) !important;
}

.dropdownItem:hover {
  background-color: var(--dark1) !important;
  color: #fff !important;
}

.googleAddress .dropdown-toggle::after {
  content: none !important;
}

.dropdown-toggle::after {
  font-size: 20px;
}

/* estra */
.black-gradient-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 140px; /* Adjust the height of the gradient as needed */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  pointer-events: none; /* Ensure the overlay doesn't interfere with the text */
}
