.bell-icon-image {
  margin-right: -5px;
}

.notification-content-container {
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.label {
  visibility: hidden;
}

.nav-icon:hover .label {
  visibility: visible;
}
