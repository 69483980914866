.fullscreen-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Black color with transparency */
  display: flex;
  justify-content: center;
  padding-top: 170px;
  z-index: 9999; /* Ensure the spinner is on top of other content */
}




.fullscreen-content {
  z-index: 10000; /* Ensure the spinner content is on top of the backdrop */
}

.program-details-wrapper .program-list {
  columns: 2;
  width: 75%;
  position: relative;
  list-style: none;
}

.program-details-wrapper .program-list li {
  margin-bottom: 16px;
}

.program-details-wrapper {
  border-radius: 16px !important;
  padding: 24px 0 56px 0;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  background-color: white;
}

.program-details-wrapper .program-name {
  color: #191919;
  font-family: Montserrat;
  font-size: 25px !important;
  margin-bottom: 32px !important;
}

.program-details-wrapper .list-heading {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
}

.program-details-wrapper .list-content {
  color: var(--dark1);
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
}

.program-details-wrapper .sub-heading {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
}

.program-details-wrapper .sublist .list-heading {
  font-size: 16px;
}

.program-details-wrapper .sublist .list-content {
  font-size: 16px;
}

.program-details-wrapper .sublist li {
  margin-bottom: 12px;
}
.detail-card{
  border-radius: 20px 20px 0px 0px ;
}
.detail-logo {
  border-radius: 8px;
  height: 32px;
  width: 32px;

}

.program-details-wrapper .college-logo {
  border-radius: 8px;
  max-height: 300px;
  height: 100%;
  max-width: 400px;
}

.logo-icon{
  max-width: 100%;
  max-height: 100%;
}
.program-details-wrapper .program-list .custom-bullet::before {
  content: "";
  height: 8px;
  width: 8px;
  position: absolute;
  margin-top: 10px;
  margin-left: -15px;
}

.program-details-wrapper .horizontal-divider {
  height: 2px;
  border-radius: 60px;
  background: rgba(0, 0, 0, 0.15);
  opacity: 0.1;
  margin: 40px 0;
}

.program-details-wrapper .program-list .styled-black-bullet::before {
  background: url("../../assets/icons/black-dot.svg") !important;
  height: 4px;
  width: 4px;
}

.program-details-wrapper .list-divider {
  width: 30px !important;
  height: 2px;
  transform: rotate(-90deg);
  border-radius: 60px;
  background: rgba(0, 0, 0, 0.15);
  margin: auto !important;
  position: absolute;
  top: 50%;
}

.program-details-wrapper .divider-first {
  right: 35%;
}

.program-details-wrapper .divider-second {
  left: 32%;
}

.program-details-wrapper .list-content a {
  word-wrap: break-word;
}

.floating-enquiry-button {
  position: fixed;
  right: 0;
  bottom: 50%;
  background-color: var(--dark1) !important;
  border-radius: 15px 0 0 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.enquiry-button {
  border: none !important;
  font-weight: 500;
  background-color: var(--dark1) !important;
  text-transform: uppercase;
  padding: 17px 4px 10px 9px !important;
  text-orientation: upright;
  writing-mode: vertical-lr;
  letter-spacing: 6px;
}

.enquiry-button:hover {
  background-color: var(--dark1) !important;
}

.program-details-wrapper table,
td,
tr,
th {
  border: none !important;
}

.program-details-wrapper th {
  width: 33.3% !important;
}

.program-details-wrapper .padded-box {
  padding: 0 52px 0 64px;
  position: relative;
}

@media only screen and (max-width: 1530px) {
  .program-details-wrapper .program-list {
    column-gap: 25px;
  }
}

@media only screen and (max-width: 1000px) {
  .program-details-wrapper .program-list {
    columns: 1;
  }
  .program-details-wrapper .college-logo {
    width: max-content;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .program-details-wrapper .list-divider {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .program-details-wrapper .list-content li a {
    word-break: break-all;
  }
}
