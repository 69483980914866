.select-form {
    box-shadow: none;
    outline: none; 
  }
.select-form option {
   border-radius: '10px';
  } 

.remark-text{
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
