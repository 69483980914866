.react-tel-input .flag-dropdown {
  border: none !important;
  background: transparent !important;
}

.react-tel-input .selected-flag {
  width: 10px;
  margin-left: 5px;
  background: transparent !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  width: 10px;
  margin-left: 5px;
  background: transparent;
}

.enquiry-form .form-floating > label {
  z-index: auto;
}

.form-floating {
  width: 100%;
}
