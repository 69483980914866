/* Search Result Card */
.resultCard .resultCardContent {
  transition: transform 0.3s;
}

.resultCard:hover .resultCardContent {
  transform: scale(1.1);
}


.resultCard .resultCardBG:hover {
  background-color: var(--light) !important;
}
.resultCardList {
  list-style-type: none;
  padding: 0;
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 18px;
  row-gap: 26px; */
}
.resultCard:nth-child(even) .resultCardBG {
  background-color: var(
    --light-blue
  ); /* Set the background color for even list items */
}

.resultCard:nth-child(odd) .resultCardBG {
  background-color: var(
    --light-blue1
  ); /* Set the background color for odd list items */
}
.delete-container {
  position: relative;
}

.delete-container::before {
  content: "Delete";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f00;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.delete-container:hover::before {
  opacity: 1;
  pointer-events: auto;
}

.viewMore a {
  background-image: linear-gradient(to right, blue, blue 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  background-clip: text; /* Standard background-clip property */
  color: transparent; /* Set the text color to transparent */
  -webkit-background-clip: text; /* For compatibility with some webkit-based browsers */
  -webkit-text-fill-color: transparent; /* For compatibility with some webkit-based browsers */
  transition: all 0.3s ease-in-out;
}

.viewMore a:before {
  content: "";
  background: var(--dark1);
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.viewMore a:hover {
  background-position: 0;
}

.viewMore a:hover::before {
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .resultCardList {
    grid-template-columns: repeat(1, 1fr);
  }
}
