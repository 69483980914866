.gic-card{
    height: 310px;
    width: 100%;
    position: relative;
    border-radius: 24px;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.06);
    /* padding: 20px 45px ; */
    overflow: hidden;
    
}
.right-corner{
    top: -32px;
    right : -33px;   
}
.flywire-corner{
    bottom: 67px;
    right : -54px;    
}
.bottom-buttons{
    bottom: 11px;
}

.arrow-button{
    height: 40px;
    width: 40px;
    padding: 10px;
    right: -18px;
    top: 10%;
    cursor: pointer;
}
.form-container{
    border-radius: 32px;
}
.form-header{
    border-radius: 32px 32px 0px 0px;

}
.file-name{
    max-width: 80% ;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
  }

.gic-card .inner-card{
    position: relative;
    background-color: white;
    z-index: 2;
    border-radius: 8px;
    box-shadow: 0px 8px 28px 0px rgba(20, 20, 43, 0.1);
    padding: 20px 18px 15px 18px;
}
.btn-gic{
    height: 33px;
    width: 100px;
}
.bank-logo{
    max-width: 137px;
    height: 25px ;
}
.button-gic{
    padding-top: 0.7rem !important ;
    padding-bottom: 0.7rem !important;
}
.id-select option{
    padding: 5px 0;

}
.blue-bgImage{
    background-image: url('../../assets/marketplace/blue-wave.svg');
    background-position: bottom ;
    background-repeat: no-repeat;
    background-size: 100% ;
    position: relative;
           
}
.flywire-logo{
    height: 30px;
    
}
.loan-text{
    left: 26px;
    top: 60%
}
.boyImage{
    bottom: 40px;
    right: 37px;
}

/* .inner-card .link-text{
   color: black;
} */

