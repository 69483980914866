.dropdown-options-program {
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  z-index: 100;
  /* font-family: Montserrat !important; */
  border: 0.5px solid rgb(230, 224, 224);
  border-radius: 0 0 20px 20px !important;
  width: 80%;
  margin-top: 2px;
  margin-right: 12px;
}
.list-item {
  border-bottom: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0.5px !important;
}
.list-group-item:last-child{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.list-item:hover {
  border-bottom: 0px !important;
  background-color: #2e397e !important;
  color: white;
}
.dropdown-options {
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  z-index: 100;
  /* font-family: Montserrat !important; */
  border: 0.5px solid rgb(230, 224, 224);
  border-radius: 5px !important;
}
