.form-control::-webkit-input-placeholder {
    color: #212529;
}
.form-floating {
    width:fit-content;
}

::placeholder {
    color: white;
    opacity: 1;
 }

.dropdown-options-container {
    max-height: 250px;
    overflow-y: auto;
    position: absolute;
    z-index: 100;
    font-family: Montserrat !important;
    border: 0.5px solid rgb(131, 130, 130);
    border-radius: 0px !important;
   
  }
  .list-item{
    border-bottom: 0px !important;
    padding-top: 0.5px !important;
    padding-bottom: 0px !important;
  }
  .list-item:hover{
    border-bottom: 0px !important;
    background-color: rgb(4, 96, 177) ;
    color: white;
  }
  .dropdown-options{
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    z-index: 100;
    font-family: Montserrat !important;
    border: 0.5px solid rgb(131, 130, 130);
    border-radius: 5px !important;
   
  }


