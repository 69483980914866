.studentNav a {
  padding: 0.5em 0.8em;
  position: relative;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 600;
}

.studentNav a::before,
.studentNav a::after {
  content: "";
  height: 14px;
  width: 14px;
  position: absolute;
  transition: all 0.35s ease;
  opacity: 0;
}

.studentNav a::before {
  content: "";
  right: 0;
  top: 0;
  border-top: 3px solid var(--student-red1);
  border-right: 3px solid var(--student-red1);
  transform: translate(-100%, 50%);
}

.studentNav a:after {
  content: "";
  left: 0;
  bottom: 0;
  border-bottom: 3px solid var(--student-red1);
  border-left: 3px solid var(--student-red1);
  transform: translate(100%, -50%);
}

.studentNav a:hover:before,
.studentNav a:hover:after {
  transform: translate(0, 0);
  opacity: 1;
}

.studentNav a:hover {
  color: var(--dark1);
}
.studentNav a.active {
  color: var(--dark1);
  border-bottom: 2px solid var(--student-red1);
}
.studentNav a.active:hover {
  color: var(--dark1);
  border-bottom: 0px solid var(--student-red1);
}
