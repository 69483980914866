.tooltip-inner {
  text-align: center !important;
  padding: 8px 30px 8px 15px !important;
  background: var(--light-blue3) !important;
  outline: 1px solid var(--dark-gray2) !important;
  border-radius: 25px !important;
  color: var(--dark1) !important;
  font-family: Montserrat !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  width: 100% !important;
  max-width: none !important;
}

.tooltip-arrow::before {
  top: 0px !important;
  width: 0 !important;
  height: 0 !important;
  border: 8px solid transparent !important;
  border-top: 0 !important;
  border-bottom: 8px solid #878787 !important;
  transform: rotate(180deg) !important;
  bottom: -20px !important;
  right: 0 !important;
  /* left: -45px !important; */
}

.tooltip {
  margin-bottom: 10px !important;
}
