.tooltip-trigger .tooltip {
  opacity: 0;
}

.tooltip-trigger:hover .tooltip {
  opacity: 1;
}

.tooltip-trigger {
  position: relative;
  display: inline;
}

.tooltip-trigger .tooltip {
  width: 100%;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  opacity: 0;
  transition: opacity 1s;
  position: absolute;
  z-index: 1;
}

.tooltip-right {
  top: -5px;
  left: 105%;
}

.tooltip-left {
  top: -5px;
  right: 105%;
}

.tooltip-top {
  bottom: 105%;
  left: 0%;
}

.tooltip-bottom {
  top: 105%;
  left: 0%;
}

.tooltip-right {
  top: -5px;
  left: 105%;
}

.tooltip-left {
  top: -5px;
  right: 105%;
}

.tooltip-top {
  bottom: 105%;
  left: 0%;
}

.tooltip-bottom {
  top: 105%;
  left: 0%;
}

.tooltip-trigger .tooltip-top::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip-trigger .tooltip-bottom::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip-trigger .tooltip-right::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.tooltip-trigger .tooltip-left::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}
