
 .edit-button{
    outline:none;
    border: none;
    outline-color: black;
    border-color: blue;
    
 } 
 .edit-button:focus {
    outline:0px !important;
  }
  .edit-button:active {
    outline-color: black;
    border-color: blue;
    }
