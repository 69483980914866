.features .nav-link.active {
  background: var(--dark1) !important;
  color: #fff !important;
  border-color: var(--dark1) !important;
}
.features .nav-link:hover {
  color: var(--dark1) !important;
  font-weight: 600;
}
.features .nav-link.active:hover {
  color: #fff !important;
}

.features .nav-link {
  height: 70px;
  width: 250px;
  transition: 0.3s !important;
  color: var(--dark1) !important;
}

.custom-accordion-header .accordion-button::after {
  display: none; 
}


.custom-accordion-header .accordion-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-accordion-header .accordion-button:not(.collapsed) {
  background: none !important;
  box-shadow: none !important;
  
}


.custom-accordion-header .accordion-button .ms-auto {
  display: flex;
  align-items: center;
}
.custom-accordion-header .accordion-button:focus {
  box-shadow: none !important;
}



