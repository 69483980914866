.select-container {
  font-family: Montserrat !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 6px !important;
  max-width: 200px !important;
  height: 45px;
  box-shadow: none;
  outline: none;
}

.dropdown-options-container {
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  z-index: 100;
  font-family: Montserrat !important;
  border: 0.5px solid rgb(131, 130, 130);
  border-radius: 0px !important;
 
}



.list-item{
  border-bottom: 0px !important;
  padding-top: 0.5px !important;
  padding-bottom: 0px !important;
}
.list-item:hover{
  border-bottom: 0px !important;
  background-color: rgb(4, 96, 177) ;
  color: white;
}


.select-container option {
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  
}

.reports .form-floating {
  width: max-content;
}
