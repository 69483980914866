/* CSS Variables */
:root {
  --h-padding: 10px;
  --h-width: 28px;
  --h-height: 20px;
  --h-background-color: transparent;
  --h-line-color: #ffffff;
  --h-hover-line-color: rgb(255, 255, 255);
  --h-line-height: 3px;
  --h-border-radius: 4px;
  --h-transition-time: 0.4s;
  --h-transition-function: ease;
  --h-transition-opacity: 0;
  --h-vertical-gap: 8px;
  --h-initial-opposition: 5px;
  --h-hide-distance: calc(var(--h-width) + var(--h-padding));
}

/* CSS Styles */
.sidemenubutton {
  padding: var(--h-padding);
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  background-color: var(--h-background-color);
  margin-top: 60px;
}

.sidemenubutton__container {
  width: var(--h-width);
  height: var(--h-height);
  position: relative;
}

.sidemenubutton:hover .sidemenubutton__inner {
  transform: translate(calc(-1 * var(--h-hide-distance)), 50%);
  opacity: var(--h-transition-opacity);
}

.sidemenubutton:hover .sidemenubutton__inner::before,
.sidemenubutton:hover .sidemenubutton__inner::after {
  transform: translate(calc(2 * var(--h-hide-distance)), 0);
  opacity: var(--h-transition-opacity);
}

.sidemenubutton.is-active .sidemenubutton__inner {
  display: none;
}

.sidemenubutton__inner {
  width: 100%;
  height: var(--h-line-height);
  background-color: var(--h-line-color);
  border-radius: var(--h-border-radius);
  position: absolute;
  transition-property: transform, opacity;
  transition-timing-function: var(--h-transition-function);
  transition-duration: var(--h-transition-time);
  top: 50%;
  transform: translate(var(--h-initial-opposition), -50%);
  opacity: 1;
}

.sidemenubutton__inner::before,
.sidemenubutton__inner::after {
  width: 100%;
  height: var(--h-line-height);
  background-color: var(--h-line-color);
  border-radius: var(--h-border-radius);
  position: absolute;
  transition-property: transform, opacity;
  transition-timing-function: var(--h-transition-function);
  transition-duration: var(--h-transition-time);
  content: "";
  opacity: 1;
  transform: translate(calc(-1 * var(--h-initial-opposition)), 0);
}

.sidemenubutton__inner::before {
  top: calc(-1 * var(--h-vertical-gap));
}

.sidemenubutton__inner::after {
  top: var(--h-vertical-gap);
}

.sidemenubutton:hover .sidemenubutton__hidden {
  opacity: 1;
  transform: translate(0, -50%);
}

.sidemenubutton:hover .sidemenubutton__hidden::before,
.sidemenubutton:hover .sidemenubutton__hidden::after {
  opacity: 1;
  transform: translate(0, 0);
}

.sidemenubutton.is-active .sidemenubutton__hidden {
  opacity: 1;
  transform: rotate(45deg);
}

.sidemenubutton.is-active .sidemenubutton__hidden::before {
  transform: translate(0, var(--h-vertical-gap)) rotate(90deg);
  transform-origin: center;
}

.sidemenubutton.is-active .sidemenubutton__hidden::after {
  transform-origin: center;
  transform: translate(0, calc(-1 * var(--h-vertical-gap))) rotate(0);
}

.sidemenubutton__hidden {
  opacity: 0;
  width: 100%;
  height: var(--h-line-height);
  background-color: var(--h-hover-line-color);
  border-radius: var(--h-border-radius);
  position: absolute;
  transition-property: transform, opacity;
  transition-timing-function: var(--h-transition-function);
  transition-duration: var(--h-transition-time);
  top: 50%;
  transform: translate(var(--h-hide-distance), -50%);
}

.sidemenubutton__hidden::before,
.sidemenubutton__hidden::after {
  width: 100%;
  height: var(--h-line-height);
  background-color: var(--h-hover-line-color);
  border-radius: var(--h-border-radius);
  position: absolute;
  transition-property: transform, opacity;
  transition-timing-function: var(--h-transition-function);
  transition-duration: var(--h-transition-time);
  content: "";
  transform: translate(calc(2 * var(--h-hide-distance)), 0);
}

.sidemenubutton__hidden::before {
  top: calc(-1 * var(--h-vertical-gap));
}

.sidemenubutton__hidden::after {
  top: var(--h-vertical-gap);
}
