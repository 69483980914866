.shadow-bottom {
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.form-floating > .form-control:not(:placeholder-shown) ~ label::after {
  background-color: transparent !important;
}
.form-floating > .form-select ~ label::after {
  background-color: transparent !important;
}

.add-region-container {
  height: 37px;
  cursor: pointer;
}

.add-region-container img.rotate-add {
  transform: rotate(45deg);
}
