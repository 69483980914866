.dotted-box {
    border: 2px dashed #2e397e; /* Change color as needed */
    display: inline-block;
    cursor: pointer;
    height: 180px;
    width: 100%;
    background-color: #edf0f2;
  }
  .text-blue{
    color: #2e397e;
  }
  .cross{
    right : 19px;
    top: -13px
  }
 .file-cross{
    top: -9px;
    right: 150px
 }