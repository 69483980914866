.accordian-header {
  padding: 18px 0 15px 16px;
  margin: 0;
  color: var(--dark-gray);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px; /* 140% */
  letter-spacing: 0.3px;
  text-transform: capitalize;
}

.accordian-item-title {
  color: var(--dark-gray);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 18.2px; /* 140% */
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.filters-wrapper {
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);
}

.accordion-button {
  padding: 17px 16px 19px 16px !important;
}
.filters-wrapper .filter-subheading {
  color: #212121;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin-bottom: 8px;
}

.filters-wrapper .range-slider {
  accent-color: var(--dark1);
}

.filters-wrapper .range-input {
  margin-bottom: 8px;
}

.filters-wrapper .accordion-button:not(.collapsed) {
  background: none !important;
  box-shadow: none !important;
  padding-bottom: 0 !important;
}

.filters-wrapper .accordion-button:focus {
  box-shadow: none !important;
}

.filters-wrapper .accordion-body {
  padding-left: 16px !important;
}
