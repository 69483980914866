.program-card-wrapper {
  background-color: var(--light-blue3);
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 30px 20px 18px 18px;
  font-family: Montserrat;
  height: 100%;
}

.program-card-wrapper .card-header-row #share-button {
  margin-right: -10px !important;
  margin-top: -7px !important;
}
.campus-list{
  white-space: nowrap;
}

.program-card-wrapper .compare-wrapper {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;
  line-height: 19.5px;
}

.program-card-wrapper .compare-wrapper input,
.program-card-wrapper .compare-wrapper .form-check,
.program-card-wrapper .compare-wrapper label {
  cursor: pointer;
}

.program-card-wrapper .card-heading {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  /* height: 30px; */
  color: var(--dark1);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.program-card-wrapper .card-subheading {
  color: var(--dark1);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  max-width: 500px;
  display: inline-block;
}

.program-card-wrapper .college-logo {
  max-height: 185px;
  max-width: 80%;
  object-fit: contain;
  border-radius: 8px;
}

.program-card-wrapper .image-column {
  display: flex;
  justify-content: start;
  /* align-items: center; */
}


.cta-program-bottom-row .card-buttons-container {
  gap: 10px;
}

.program-card-wrapper .cta-icons {
  width: max-content;
}
.cta-program-bottom-row{
  bottom: 10px;
 
  width: 95%;
  
}

.cta-program-bottom-row button {
  border-radius: 60px;
  border-color: var(--dark1);
  background: var(--dark1);
  padding: 5px 12px;
  font-size: 12px;
  font-family: Montserrat;
  width: 110px;
  font-weight: 500;
}

.cta-program-bottom-row button:hover {
  background: var(--dark1);
}

.program-card-wrapper .list-heading {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
}

.program-card-wrapper .program-list {
  width: 100%;
  /* columns: unset;
  display: grid; */
  /* grid-template-columns: repeat(1, 1fr); */
  padding: 0;
  list-style: disc;
  /* column-gap: 20px; */
}

.program-card-wrapper .list-content {
  color: var(--dark4);
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.program-card-wrapper .list-content-campus {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 102px;
  display: inline-block;
  /* display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */

  margin-bottom: -3px;
}

.program-card-wrapper .program-list li {
  /* margin-bottom: 8px; */
  line-height: 25px;
  margin-right: 7px;
}

.program-card-wrapper .card-subheading-container {
  margin-left: -23px;
}

/* .program-card-wrapper .program-list li:last-child {
  margin-bottom: 0;
}

.program-card-wrapper .program-list li:last-child,
.program-card-wrapper .program-list li:nth-last-child(2) {
  margin-bottom: 0;
} */

.program-list-wrapper {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 18px;
  row-gap: 26px;
}

@media only screen and (max-width: 1200px) {
  .program-list-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .program-card-wrapper .program-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
