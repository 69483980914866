.nav a {
  padding: 0.5em 0.8em;
  position: relative;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 600;
}

.nav a::before,
.nav a::after {
  content: "";
  height: 14px;
  width: 14px;
  position: absolute;
  transition: all 0.35s ease;
  opacity: 0;
}

.nav a::before {
  content: "";
  right: 0;
  top: 0;
  border-top: 3px solid var(--dark1);
  border-right: 3px solid var(--dark1);
  transform: translate(-100%, 50%);
}

.nav a:after {
  content: "";
  left: 0;
  bottom: 0;
  border-bottom: 3px solid var(--dark1);
  border-left: 3px solid var(--dark1);
  transform: translate(100%, -50%);
}

.nav a:hover:before,
.nav a:hover:after {
  transform: translate(0, 0);
  opacity: 1;
}

.nav a:hover {
  color: var(--dark1);
}
.mainNav .nav a.active {
  color: var(--dark1);
  border-bottom: 2px solid var(--dark1);
}
.mainNav .nav a.active:hover {
  color: var(--dark1);
  border-bottom: 0px solid var(--dark1);
}



@media (max-width: 576px) {
  .navbar-collapse {
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: auto;
    max-height: 100%;
    overflow-y: auto;
    transition: width 0.4s ease;
    background-color: #ececec;
    z-index: 1050; 
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 13px 12px 13px 15px ;
    border-radius: 16px 0px 0px 16px;
  }
  .nav a:hover:before,
.nav a:hover:after {
  transform: translate(0, 0);
  opacity: 0;
}
  


  .navbar-collapse.show {
    width: 160px; 
  }

  .navbar-nav {
    flex-direction: column;
    width: 100%;
  }
  .mainNav .nav a.active {
    color: var(--dark1);
    text-decoration: underline;
    border-bottom: 0px solid var(--dark1);
    font-size: 16px;
    font-weight: 400;
  }
  .mainNav .nav a{
    font-size: 16px;
    font-weight: 400;
  }

  .nav-item {
    width: 100%;
  }

  .nav-link {
    width: 100%;
    text-align: right;
    padding: 10px 20px; 
  }
}


