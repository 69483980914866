:root {
  --dark0: #161616;
  --dark1: #2e397e;
  --dark2: #5a5a72;
  --dark3: #51598a;
  --dark4: #443e63;
  --stripped1: #e3e7fe;
  --stripped2: #f0f2ff;
  --normal-blue: #3f59ff;
  --normal-green: #2eb82c;
  --normal-green1: #54896f;
  --normal-green2: #39ac80;
  --normal-yellow: #ffb635;
  --normal-red: #ff3f6d;
  --normal-ornage: #fc7753;
  --normal-ornage1: #c05000;
  --light: #ececec;
  --light-blue: #ecf2ff;
  --light-blue1: #f0f2ff;
  --light-blue2: rgba(46, 57, 126, 0.2);
  --light-blue3: #f3f8ff;
  --light-green: #e7fee5;
  --light-yellow: #fef5e5;
  --light-red: #ffdfe7;
  --light-gray: #8b8b8b;
  --light-gray1: #d1d1d1;
  --light-gray2: #e6e6e6;
  --note-blue: #52c4f4;
  --dark-gray: #212121;
  --dark-gray2: #878787;
  --orange: #fc7753;
  /* students */
  --student-red1: #d70027;
  --student-light-gray1: #e5e3e4;
  --student-light1: #5a5a72;
}
@font-face {
  font-family: "Axiforma-Regular";
  src: url("./assets/Axiforma/Axiforma-Regular.eot"),
    url("./assets/Axiforma/Axiforma-Regular.woff2") format("woff2"),
    url("./assets/Axiforma/Axiforma-Regular.woff") format("woff"),
    url("./assets/Axiforma/Axiforma-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Axiforma-Bold";
  src: url("./assets/Axiforma/Axiforma-Bold.eot"),
    url("./assets/Axiforma/Axiforma-Bold.woff2") format("woff2"),
    url("./assets/Axiforma/Axiforma-Bold.woff") format("woff"),
    url("./assets/Axiforma/Axiforma-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat";
  src: url("/public/assets/fonts/Montserrat-VariableFont_wght.ttf")
    format(truetype);
}

@font-face {
  font-family: "Roboto";
  src: url("/public/assets/fonts/Roboto.ttf") format(truetype);
}

html {
  font-size: 10px;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Axiforma-Regular" !important;
  overflow-x: hidden;

}


h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

.font-bold {
  font-family: "Axiforma-Bold" !important;
}
.text1-1 {
  font-size: 1.1rem !important;
}
.text1-2 {
  font-size: 1.2rem !important;
}
.text1-3 {
  font-size: 1.3rem !important;
}
.text1-4 {
  font-size: 1.4rem !important;
}
.text1-5 {
  font-size: 1.5rem !important;
}
.text1-6 {
  font-size: 1.6rem !important;
}
.text1-7 {
  font-size: 1.7rem !important;
}
.text1-8 {
  font-size: 1.8rem !important;
}
.text1-9 {
  font-size: 1.9rem !important;
}
.text2 {
  font-size: 2rem !important;
}
.text2-2 {
  font-size: 2.2rem !important;
}
.text2-5 {
  font-size: 2.5rem !important;
}
.text3 {
  font-size: 3rem !important;
}
.text4 {
  font-size: 4rem !important;
}
.text5 {
  font-size: 5rem !important;
}
.text6 {
  font-size: 6rem !important;
}
.text-dark0 {
  color: var(--dark0) !important;
}
.text-dark1 {
  color: var(--dark1) !important;
}
.text-dark2 {
  color: var(--dark2) !important;
}
.text-light-gray {
  color: var(--light-gray) !important;
}
.text-orange {
  color: var(--orange) !important;
}
.text-normal-orange1 {
  color: var(--normal-ornage1) !important;
}
.text-normal-red {
  color: var(--normal-red) !important;
}
.text-normal-green {
  color: var(--normal-green) !important;
}
.text-normal-green1 {
  color: var(--normal-green1) !important;
}
.text-normal-yellow {
  color: var(--normal-yellow) !important;
}
.text-tick-blue {
  color: var(--note-blue) !important;
}
.text-normal-blue {
  color: var(--normal-blue) !important;
}

.bg-white {
  background-color: #fff;
}
.bg-dark0 {
  background-color: var(--dark0) !important;
}
.bg-dark1 {
  background-color: var(--dark1) !important;
}
.bg-dark2 {
  background-color: var(--dark2) !important;
}
.bg-dark3 {
  background-color: var(--dark3) !important;
}
.bg-stripped1 {
  background-color: var(--stripped1) !important;
}
.bg-stripped2 {
  background-color: var(--stripped2) !important;
}
.bg-normal-blue {
  background-color: var(--normal-blue) !important;
}
.bg-normal-green {
  background-color: var(--normal-green) !important;
}
.bg-normal-green1 {
  background-color: var(--normal-green1) !important;
}
.bg-normal-green2 {
  background-color: var(--normal-green2) !important;
}
.bg-normal-yellow {
  background-color: var(--normal-yellow) !important;
}
.bg-normal-red {
  background-color: var(--normal-red) !important;
}
.bg-normal-orange {
  background-color: var(--normal-ornage) !important;
}
.bg-normal-orange1 {
  background-color: var(--normal-ornage1) !important;
}
.bg-orange {
  background-color: var(--orange) !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-light0 {
  background-color: var(--light) !important;
}
.bg-light-gray {
  background-color: var(--light-gray) !important;
}
.bg-light-gray1 {
  background-color: var(--light-gray1) !important;
}
.bg-light-gray2 {
  background-color: var(--light-gray2) !important;
}
.bg-light-blue {
  background-color: var(--light-blue) !important;
}
.bg-light-blue1 {
  background-color: var(--light-blue1) !important;
}
.bg-light-blue2 {
  background-color: var(--light-blue2) !important;
}
.bg-light-blue3 {
  background-color: var(--light-blue3) !important;
}
.bg-light-green {
  background-color: var(--light-green) !important;
}
.bg-light-yellow {
  background-color: var(--light-yellow) !important;
}
.bg-light-red {
  background-color: var(--light-red) !important;
}
.border-orange {
  border: 0.2rem var(--normal-ornage) solid !important;
}
.dashed-border {
  border: 4px dashed var(--orange) !important;
}
.promo-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
}
.border-dark1 {
  border: 1px solid var(--dark1) !important;
}
.custom-hr{
  border: none;
  border-top: 3px solid var(--dark1) !important;

}
.sahibhangLink {
  font-size: 2rem;
  color: #fc7753;
  text-decoration: none;
}
.sahibhangLink:hover {
  text-decoration: underline;
}

[data-aos] {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease;
}

[data-aos].aos-animate {
  opacity: 1;
  transform: translateY(0);
}

/* Student web pages */
.text-student-red1 {
  color: var(--student-red1);
}
.text-student-light1 {
  color: var(--student-light1);
}
.bg-student-light-gray1 {
  background-color: var(--student-light-gray1);
}
.bg-student-red1 {
  background-color: var(--student-red1);
}
.btn-student-gray {
  background-color: var(--student-light-gray1) !important;
  color: black !important;
}
.btn-student-red {
  background-color: var(--student-red1) !important;
  color: white !important;
}
.blog-border {
  border-top: 1px var(--student-light-gray1) solid;
  border-right: 1px var(--student-light-gray1) solid;
  border-left: 1px var(--student-light-gray1) solid;
  border-bottom: 4px var(--student-red1) solid;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 4px;
}

.form-label {
  top: -5px;
  left: 8px;
  font-size: 9px !important;
  background: white;
}

.animated-placeholder {
  position: relative;
  /* -webkit-animation-name: animatePlaceholder; */
  /* -webkit-animation-duration: 2s; */
  animation-name: animatePlaceholder;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  /* animation-timing-function: ease-in; */
}

.homePageView {
  min-height: 100vh;

  @media (max-width: 768px) {
    min-height: initial;
  }
}

.gradiantButton {
  background: linear-gradient(
    87.67deg,
    #ffffff 0%,
    #020024 0.01%,
    #2e397e 35.31%,
    #00d4ff 100%
  );
  color: white;
  border: none;
}
.blog-bg {
  background-image: url("./assets/common/blogButton.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

/* @-webkit-keyframes animatePlaceholder {
  from {
    top: 0px;
    opacity: 0;
  }
  to {
    top: -10px;
    opacity: 1;
  }
} */

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes animatePlaceholder {
  0% {
    transform: translateY(30px);
  }

  20%,
  80% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-30px);
  }
}

.Toastify__toast {
  padding: 0 !important;
}
.Toastify__toast-body {
  padding: 0px !important;
}

.Toastify__toast--success {
  .Toastify__toast-icon {
    height: 80px !important;
    width: 70px !important;
    background-color: #28a745;
    align-items: center;
    justify-content: center;
  }
  .Toastify__toast-body > div:last-child {
    /* content: "A Successfull Toast"; */
    /* font-weight: bolder; */
    color: #000;
    display: block;
  }
}

.Toastify__toast--error {
  .Toastify__toast-icon {
    height: 80px !important;
    width: 70px !important;
    background-color: #ff0000;
    align-items: center;
    justify-content: center;
  }
  .Toastify__toast-body > div:last-child {
    /* content: "An Error Occured"; */
    /* font-weight: bolder; */
    color: #000;
    display: block;
  }
}

.Toastify__toast-icon svg {
  height: 50% !important;
  width: 50% !important;
  fill: white !important;
}
.blog-container{
  height: 245px !important;
  width: 180px !important;
}

.note-input {
  width: 45%;
}
.note-file {
  width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.reply-note {
  max-width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.note-cross {
  top: -25%;
  right: 5px;
}
.note-image-cross {
  top: -30%;
  right: -13px;
}
.reply-tooltip {
  position: absolute;
  width: 50px !important;
  bottom: 90% !important;
  left: -20px !important;
}
.img-compare {
  left: 50%;
  transform: translateX(-50%);
}
.blog-carousel{
  height: 130px;
  overflow-y: auto;
}
.blog-bg {
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 20%,
      rgba(255, 255, 255, 0.9) 22%,
      rgba(255, 255, 255, 0.8) 28%,
      rgba(255, 255, 255, 0.5) 32%,
      rgba(255, 255, 255, 0)
    ),
    url("./assets/images/blogHeader.png") no-repeat center center;
  background-size: 80% 550px;
  background-position: 100% 0%;
}
.sub-text {
  font-size: 20px;
  font-weight: 400;
}
.carousel .slick-slide{
  padding: 0 100px;
} 
.carousel .slick-list {
  height: 280px !important;
 
}



